var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "margin-bottom": ['16px', '24px']
    }
  }, [!!_vm.label ? _c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isInvalid ? 'danger.400' : !_vm.isDataEmpty(_vm.value) ? 'primary.400' : '#555',
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.isRequired ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1) : _vm._e(), _vm.subLabel ? _c('c-box', {
    attrs: {
      "as": "p",
      "font-size": ['14px', '16px'],
      "color": "#555",
      "font-weight": "400",
      "font-family": "Roboto",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.subLabel) + " ")]) : _vm._e(), _c('c-grid', {
    attrs: {
      "gap": _vm.gap,
      "template-columns": _vm.templateColumns
    }
  }, [_vm._l(_vm.options, function (option, index) {
    return [_c('c-checkbox', {
      key: index,
      attrs: {
        "is-checked": _vm.isIncludesValue(option.value),
        "value": option.value,
        "is-disabled": _vm.isDisabled,
        "color": _vm.isInvalid ? 'danger.400' : _vm.isIncludesValue(option.value) ? 'primary.400' : '#555',
        "variant-color": _vm.isInvalid ? 'danger' : _vm.isIncludesValue(option.value) ? 'primary' : 'gray',
        "font-weight": _vm.isIncludesValue(option.value) ? '500' : '400',
        "size": "lg",
        "font-size": ['14px', '16px'],
        "spacing": "2"
      },
      on: {
        "change": function change(_, $e) {
          return _vm.handleCheckboxChange($e, option.value);
        }
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]), _vm._t("default", null, {
      "isSelected": _vm.isIncludesValue(option.value),
      "option": option
    })];
  })], 2), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.helperText) + " ")]) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey
    }, [_vm._v(" ● " + _vm._s(invalidTextValue) + " ")]);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }