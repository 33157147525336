<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :margin-bottom="['16px', '24px']"
  >
    <c-form-label
      v-if="!!label"
      :font-size="['14px', '16px']"
      :color="isInvalid ? 'danger.400' : !isDataEmpty(value) ? 'primary.400' : '#555'"
      font-weight="400"
      font-family="Roboto"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <c-box
      v-if="subLabel"
      as="p"
      :font-size="['14px', '16px']"
      color="#555"
      font-weight="400"
      font-family="Roboto"
      margin-bottom="8px"
    >
      {{ subLabel }}
    </c-box>
    <!--      :is-inline="isInline"-->
    <c-grid
      :gap="gap"
      :template-columns="templateColumns"
    >
      <template v-for="(option, index) in options">
        <c-checkbox
          :key="index"
          :is-checked="isIncludesValue(option.value)"
          :value="option.value"
          :is-disabled="isDisabled"
          :color="isInvalid ? 'danger.400' : isIncludesValue(option.value) ? 'primary.400' : '#555'"
          :variant-color="isInvalid ? 'danger' : isIncludesValue(option.value) ? 'primary' : 'gray'"
          :font-weight="isIncludesValue(option.value) ? '500' : '400'"
          size="lg"
          :font-size="['14px', '16px']"
          spacing="2"
          @change="(_, $e) => handleCheckboxChange($e, option.value)"
        >
          {{ option.label }}
        </c-checkbox>
        <slot
          :is-selected="isIncludesValue(option.value)"
          :option="option"
        />
      </template>
      <!--      </c-checkbox-group>-->
    </c-grid>
    <c-form-helper-text
      v-if="isHelper"
      font-size="12px"
    >
      {{ helperText }}
    </c-form-helper-text>
    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
      >
        &#9679; {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>

import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  name: 'BaseInputCheckbox2',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    gap: {
      type: String,
      default: '',
    },
    templateColumns: {
      type: String,
      default: '',
    },
  },
  emits: ['blur', 'input'],
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('blur')
      },
    },
  },
  methods: {
    isDataEmpty,
    isIncludesValue(value) {
      return this.value ? this.value.includes(value) : false
    },
    handleCheckboxChange($e, value) {
      const { checked } = $e.target
      if (checked) {
        this.modelValue.push(value)
      } else {
        this.modelValue = this.modelValue.filter((el) => el !== value)
      }
    },
  },
}
</script>
